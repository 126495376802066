/**
 * @description 对象数组去重
 * @param {arr} 数据源（数组）
 * @param {key} 键
*/
export function duplicate(arr, key) {
  if (key) {
    const hash = {};
    const result = arr.reduce((item, next) => {
      // eslint-disable-next-line no-unused-expressions
      hash[next[key]] ? '' : (hash[next[key]] = true && item.push(next));
      return item;
    }, []);
    return result;
  // eslint-disable-next-line no-else-return
  } else {
    const x = new Set(arr);
    return [...x];
  }
}

/**
 * @description 普通一员数组是否有重复数据
*/
export function isRepeat(arr = []) {
  if (arr.length === 0) return false;
  const result = [...new Set(arr)];
  return arr.length !== result.length;
}

/**
 * @desc 获取当前时间 yyyy-MM-DD
*/
export function getCurrentDate() {
  const date = new Date();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const compleMonth = Number(month) < 10 ? `0${month}` : month;
  const compleDay = Number(month) < 10 ? `0${day}` : day;
  return {
    year: date.getFullYear(),
    month: compleMonth,
    day: compleDay,
    hour: date.getHours(),
    seconds: date.getSeconds(),
    minutes: date.getMinutes(),
  };
}

/**
 * @desc 格式化时间
*/
export function dateFormat(newfmt, newdate) {
  let ret;
  const date = new Date(newdate);
  let fmt = newfmt;
  const opt = {
    'Y+': date.getFullYear().toString(), // 年
    'm+': (date.getMonth() + 1).toString(), // 月
    'd+': date.getDate().toString(), // 日
    'H+': date.getHours().toString(), // 时
    'M+': date.getMinutes().toString(), // 分
    'S+': date.getSeconds().toString(), // 秒
    // 有其他格式化字符需求可以继续添加，必须转化成字符串
  };
  Object.keys(opt).forEach((k) => {
    ret = new RegExp(`(${k})`).exec(fmt);
    if (ret) {
      fmt = fmt.replace(
        ret[1],
        ret[1].length === 1 ? opt[k] : opt[k].padStart(ret[1].length, '0'),
      );
    }
  });
  return fmt;
}

/** @desc 生成简便的UUID */
export function guid() {
  function S4() {
    // eslint-disable-next-line no-bitwise
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  }
  return `${S4()}${S4()}`;
  // return (`${S4() + S4()}-${S4()}-${S4()}-${S4()}-${S4()}${S4()}${S4()}`);
}
